<template>
  <div class="main">
    <div class="page-bg"></div>

    <div class="animation-wrapper">
      <div class="particle particle-1"></div>
      <div class="particle particle-2"></div>
      <div class="particle particle-3"></div>
      <div class="particle particle-4"></div>
    </div>
    <v-img
      lazy-src="https://picsum.photos/id/11/10/6"
      max-height="400"
      width="100%"
      cover
      src="https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/btcBg.jpeg?alt=media&token=89e802e6-37b5-4fdc-8560-6b4f23aa938f"
    >
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-col cols="12" md="6">
          <h1 class="text-h3 font-weight-bold mb-4 textWhite">History</h1>
          <v-card-actions>
            <v-icon color="#d4b506">mdi-home</v-icon>
            <v-btn text color="#fff" @click="navigate('dashboard')">
              Home
            </v-btn>
            <div class="textWhite">/ History</div>
          </v-card-actions>
        </v-col>
        <v-col cols="12" md="6"> </v-col>
      </v-row>
    </v-img>

    <v-container>
      <v-simple-table dark class="mt-8 mb-10">
        <template v-slot:default>
          <thead color="red">
            <tr>
              <th class="text-left">Transaction Type</th>
              <th class="text-left">Processing</th>
              <th class="text-left">Available</th>
              <th class="text-left">Status</th>
              <th class="text-left">Wallet ID</th>
            </tr>
          </thead>
          <tbody>
            <tr :key="index" v-for="(i, index) in getTransactionHistory">
              <td>{{ i.transactionType }}</td>
              <td>{{ i.currencyType }}</td>
              <td>{{ DisplayCurrency(i.amount) }}</td>
              <td>{{ i.status }}</td>
              <td>{{ i.walletId }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
    <v-footer color="black" class="footer">
      <v-row justify="center" no-gutters class="pb-7">
        <v-col class="py-4 text-center white--text" cols="12">
          <div class="text-center">
            <h1 class="white--text">
              <v-icon large color="#d4b506">mdi-bitcoin</v-icon>
              LXGMiners
            </h1>
          </div>
        </v-col>
        <v-btn
          v-for="link in links"
          :key="link"
          color="white"
          text
          rounded
          class="my-2"
        >
          {{ link }}
        </v-btn>
        <v-col class="py-4 white--text" cols="12">
          © {{ new Date().getFullYear() }} —
          <strong
            ><span class="goldColor">LXGMINERS INC.</span> All rights
            reserved</strong
          >
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>
     
<script>
import currencyFormatter from "currency-formatter";

export default {
  components: {},
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    links: ["Home", "Privacy & Policy", "Terms & Conditions"],
  }),
  computed: {
    getTransactionHistory() {
      return this.$store.getters.getTransactionHistory;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "USD",
      });
    },
    GetTransactionHistory() {
      let payload = {
        token: this.getToken,
      };
      this.$store.dispatch("GetTransactionHistory", payload);
    },
  },
  created() {
    this.GetTransactionHistory();
  },
};
</script>
    
    <style lang="scss" scoped>
.footer {
  border-top: 1px solid #d4b506 !important;
}
.card {
  min-height: 600px;
  background-color: rgb(5, 5, 5, 0.8) !important;
  padding: 55px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main {
  flex: 1;
  background-color: black;
  height: 100vh;
}
.section-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 55vh;
}
.section-2 {
  background-color: rgb(5, 5, 5, 0.8) !important;
  margin: 5em;
}
.goldColor {
  color: #d4b506 !important;
}
.textWhite {
  color: white;
}
/* Config */
$color-bg: #1e1e1e;
$color-particle: #fff;
$spacing: 2560px;
$time-1: 60s;
$time-2: 120s;
$time-3: 180s;
$time-4: 200s;

/* mixins */
@function particles($max) {
  $val: 0px 0px $color-particle;
  @for $i from 1 through $max {
    $val: #{$val}, random($spacing) + px random($spacing) + px $color-particle;
  }
  @return $val;
}

@mixin particles($max) {
  box-shadow: particles($max);
  border-radius: 50%;
}

/* Styles */
html,
body {
  height: 100%;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  overflow: hidden;
}

.page-bg,
.animation-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-bg {
  background: $color-bg;
  background-blend-mode: screen;
  background-size: cover;
  filter: grayscale(100%);
  z-index: -1;
}

.particle,
.particle:after {
  background: transparent;
}

.particle:after {
  position: absolute;
  content: "";
  top: $spacing;
}

.particle-1 {
  animation: animParticle $time-1 linear infinite;
  @include particles(1000);
  height: 2px;
  width: 2px;
}

.particle-1:after {
  @include particles(900);
  height: 2px;
  width: 2px;
}

.particle-2 {
  animation: animParticle $time-2 linear infinite;
  @include particles(800);
  height: 2px;
  width: 2px;
}

.particle-2:after {
  @include particles(500);
  height: 3px;
  width: 3px;
}

.particle-3:after {
  @include particles(1100);
  height: 3px;
  width: 3px;
  border-radius: 50%;
}

.particle-4 {
  animation: animParticle $time-4 linear infinite;
  @include particles(1200);
  height: 1px;
  width: 1px;
}

.particle-4:after {
  @include particles(700);
  height: 1px;
  width: 1px;
}

@keyframes animParticle {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY($spacing * -1);
  }
}

.page-wrapper {
  text-align: center;
  color: #fff;
  z-index: 2;
}

h4 {
  color: #fff;
  font-family: Monoton;
  -webkit-animation: neon1 1.5s ease-in-out infinite alternate;
  -moz-animation: neon1 1.5s ease-in-out infinite alternate;
  animation: neon1 1.5s ease-in-out infinite alternate;
}

h4:hover {
  color: #ff1177;
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
}

/*glow for webkit*/

@-webkit-keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}

/*glow for mozilla*/

@-moz-keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}

/*glow*/

@keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}
</style>
    